
p {
    &.large {
        font-size: 1.8rem;
    }
}

.c {
    &-dark {
        color: $font-dark;
        fill: $font-dark;
    }
    &-grey {
        color: $font-grey;
        fill: $font-grey;
    }
    &-grey-1 {
        color: $font-grey-1;
        fill: $font-grey;
    }
    &-red {
        color: var(--gradient-red);
        fill: var(--gradient-red);
    }
    &-orange {
        color: var(--gradient-orange);
        fill: var(--gradient-orange);
    }
    &-blue {
        color: $font-blue;
        fill: $font-blue;
    }
    &-purple {
        color: var(--purple-1);
        fill: var(--purple-1);
    }
    &-white {
        color: #fff;
        fill: #fff;
    }
}

.upper {
    text-transform: uppercase;
}

.ls {
    &-1 {
        letter-spacing: .1rem;
    }
    &-2 {
        letter-spacing: -.1rem;
    }
}

.paragraph {
    &-big {
        font-size: 2rem;
        font-weight: 600;
        line-height: 3rem;
    }

    &-small {
        font-size: 1.4rem;

        @media (max-width: 991px) {
            font-size: 1.6rem;
        }
    }
}

.f-w {
    &-700 {
        font-weight: 700;
    }
}

.pattern-image {
    padding: 5rem 0;
    &-wrapper {
        position: relative;
    }
    
    img {
        position: relative;
        z-index: 2;
        max-width: 100%;
        object-fit: cover;
        @include border-radius(2rem);

        @media (max-width: 991px) {
            height: 60%;
        }

    }

    .background-pattern {
        width: 95%;
        height: calc(100% + 10rem);
        

        left: initial;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        z-index: 1;

        @include border-radius(2rem);
    }

    &-1 {
        text-align: right;
        .background-pattern {
            width: 82.5%;
            right: -7rem;
        }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        &:not(.pattern-image-1) {
            .background-pattern {
                right: -6rem;
            }
        }
    }

    @media (max-width: 991px) {
        &:not(.pattern-image-1) {
            .background-pattern {
                right: -3rem;
            }
        }

        &-1 {
            text-align: left;
            img {
                max-width: 90%;
            }
            .background-pattern {
                width: 90%;
                right: -2rem;
            }
        }
    }

    @media (max-width: 575px) {
        &-1 {
            .background-pattern {
                right: -1rem;
            }
        }
    }

}

.link-underline {
    > span {
        position: relative;
        @include animateIt;
        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0rem;
            bottom: -.25rem;
            height: .1rem;
            background-color: #fff;
            transform-origin: right center;
            transform: scale(0, 1);
            transition: transform .3s ease;
        }
    }
    
    
    &:hover {
        color: unset;
        > span {
            text-decoration: none;
            &::before {
                transform-origin: left center;
                transform: scale(1, 1);
            }
        }
    }

    &-1 {
        > span::before {
            background-color: $font-grey;
        }
    }
}

.slider-navigation {
    display: flex;
    justify-content: flex-end; 
    align-items: flex-end;
    width: 100%;
    height: 100%;

    > div {
        cursor: pointer;
        &:not(:last-child) {
            margin-right: 2rem;
        }
    }

    i {
        font-size: 6rem;
        color: $color-grey;
        @include animateIt;
        &:hover {
            color: var(--orange-drop-shadow-1);
        }
    }

    @media (max-width: 991px) {
        justify-content: center;
        align-items: center;
    }
}

.form-floating {
     .form-check-input {
        width: 3.75em;
        height: 2.125em;
        margin-right: 0.5rem;
        border-color: var(--purple-drop-shadow);
        filter: hue-rotate(93deg);
    }
    .form-check-input + p a {
        color: var(--gradient-red);
    }
    .input {
        height: 6.3rem;
        font-size: 1.6rem;
        font-weight: 500;
        color: $font-grey-1;

        padding: 0 1rem;

        border: none;
        border-bottom: .2rem solid $color-grey-2;

        &:focus,
        &:active {
            border-color: var(--purple-drop-shadow);
            outline: none;
            box-shadow: none;
        }
    }
    label {
        padding: 1.9rem 1rem;


        font-size: 1.6rem;
        font-weight: 500;
        color: $font-grey-1
    }

    &:nth-child(n+2) {
        margin-top: 2.7rem;
        margin-bottom: 5em;
    }

    .textarea {
        height: 100%;
        padding: 2.3rem 1rem !important;
        resize: none;
    }

    &.textarea-form {
        height: 100%;
    }
}

 .form-floating {
    .form-select-lg, .multiselect-container, .multiSelectContainer input {
    height: 3.5em;
    line-height: 1.85em;
    
    }
        .searchWrapper.is-invalid, .searchWrapper.is-valid, {
            
            background-position:  right calc(2.375em + .1875rem) center;
        
    }
        .is-invalid {
            
            border-color: var(--bs-form-invalid-border-color);
            padding-right: calc(1.5em + .75rem);
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right calc(.375em + .1875rem) center;
            background-size: calc(.75em + .375rem) calc(.75em + .375rem);
        
    }
    .is-valid {
        border-color: var(--bs-form-valid-border-color);
        padding-right: calc(1.5em + .75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(.375em + .1875rem) center;
        background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    }       
    
    
    .chip {
        margin-top: 0.5em;
        background-color: var(--gradient-red);
    }
    .multiSelectContainer li.highlightOption, .multiSelectContainer li:hover {
        background-color: var(--gradient-red);
        font-size: 1.35em;
    }
    .multiSelectContainer li {
        font-size: 1.35em;
    }
    .form-select-lg + label,  div + label{
        padding: 1rem 1rem;
    }

 }
 .form-floating>div~label, .form-floating>div:focus~label, .form-floating>div:not(:placeholder-shown)~label {
    color: rgba($font-grey,100);
    background-color: #fff;
}
.form-floating>.form-control:focus~label, 
.form-floating>.form-control:not(:placeholder-shown)~label, 
.form-floating>.form-select~label,.form-floating>div~label,
.form-floating>div:focus~label,.form-floating>div:not(:placeholder-shown)~label
{
    transform: scale(.85) translateY(-2rem) translateX(.15rem);    
    margin-left: 0.25em;
}

.drop-shadow {
    @include drop-shadow($color: var(--gradient-purple-rgba-2));

    &-1 {
        @include drop-shadow($color: var(--blue-rgba-4));
    }

    &-2 {
        @include drop-shadow($color: var(--purple-drop-shadow-rgba-1));
    }

    &-3 {
        @include drop-shadow($color: var(--gradient-red-1-rgba-2));
    }

    &-team {
        &-1 {
            @include drop-shadow($color: var(--gradient-orange-rgba-2), $yOffset: 15px, $blurRadius: 10px);
        }
        &-2 {
            @include drop-shadow($color: var(--gradient-red-rgba-2), $yOffset: 15px, $blurRadius: 10px);
        }
        &-3 {
            @include drop-shadow($color: var(--gradient-purple-rgba-2), $yOffset: 15px, $blurRadius: 10px);
        }
    }
}

.gprev, .gnext {
    display: none;
}

.social-gradient {
    .twitter {
        i {
            color: var(--orange-2);
        }
    }
    .facebook {
        i {
            color: var(--red-3);
        }
    }
    .linkedin {
        i {
            color: var(--purple-3);
        }
    }
    .youtube {
        i {
            color: var(--purple-4);
        }
    }
}

ul.icon-list {
    list-style: none;
    margin-left: 3rem;

    @media (max-width: 991px) {
        margin-left: 1rem;
    }

    li {
        display: flex;
        align-items: flex-start;
        span {
            display: inline-block;
            width: calc(100% - 2.5rem);
        }

        &::before {
            content: '\f0a9';
            font-family: 'Line Awesome Free';
            font-size: 2rem;
            font-weight: 900;
            width: 1.8rem;
            display: inline-block;
            position: relative;
            margin-right: .7rem;
        }
    }


    &-1 {
        li {
            &::before {
                color: var(--gradient-orange);
            }
        }
    }
    

    &-2 {
        li {
            &::before {
                color: var(--gradient-red);
            }
        }
    }

}